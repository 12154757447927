




import { Component, Vue } from 'vue-property-decorator';
import UserDocs from '@/components/user/userDocs.vue';

@Component({
  components: { UserDocs }
})
export default class CheckoutStepTwo extends Vue {
  private isUserProfile = false;

  private changeActiveStep(step: number) {
    this.$emit('changeActiveStep', step);
  }
}
